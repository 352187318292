<template>
  <VueDraggableResizable :w="w" :h="h" :x="x" :y="y" :class="classes" @dragstop="onDragStop" @resizestop="onResizeStop">
    <textarea-autosize ref="text" :style="textStyle" class="text" placeholder="Type Here" @focus.native="onFocus" @blur.native="onBlur" v-model="content.text" />
  </VueDraggableResizable>
</template>
<script>
  import VueDraggableResizable from 'vue-draggable-resizable';
  import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
  import store from '../store.js';

  export default {
    components: {
      VueDraggableResizable
    },
    props: {
      content: Object
    },
    data() {
      const width = this.content.width;
      const height = this.content.height;
      const left = this.content.left || 0;
      const top = this.content.top || 0;
      return {
        left,
        top,
        width,
        height
      };
    },
    computed: {
      classes() {
        return {
          'text-item': true,
          focused: this.focused
        };
      },
      textStyle() {
        let [ fontSize ] = this.content.fontSize.match(/\d+/);
        const style = {
          color: this.content.color,
          fontFamily: this.content.fontFamily,
          fontSize: `${this.$store.getters.toPixels(fontSize)}px`,
          textAlign: this.content.textAlign
        };
        return style;
      },
      w() {
        return store.getters.toPixels(this.width);
      },
      h() {
        return store.getters.toPixels(this.height);
      },
      x() {
        return store.getters.toPixels(this.left);
      },
      y() {
        return store.getters.toPixels(this.top);
      }
    },
    methods: {
      onFocus() {
        // So the height updates if the font size changes due
        // to a resize from the keyboard popping up. Give it a moment
        // for the resize madness to stop
        this.focused = true;
        setTimeout(() => {
          if (this.$refs.text) {
            this.$refs.text.resize();
          }
        }, 500);
      },
      onBlur() {
        this.focused = false;
        this.emitUpdate();
        // So the height updates if the font size changes due
        // to a resize from the keyboard popping up. Give it a moment
        // for the resize madness to stop
        setTimeout(() => {
          if (this.$refs.text) {
            this.$refs.text.resize();
          }
        }, 500);
      },
      onDragStop(left, top) {
        this.left = store.getters.toPoints(left);
        this.top = store.getters.toPoints(top);
        this.emitUpdate();
      },
      onResizeStop(left, top, width, height) {
        this.left = store.getters.toPoints(left);
        this.top = store.getters.toPoints(top);
        this.width = store.getters.toPoints(width);
        this.height = store.getters.toPoints(height);
        this.emitUpdate();
      },
      emitUpdate() {
        const result = {
          ...this.content,
          left: this.left,
          top: this.top,
          width: this.width,
          height: this.height
        };
        this.$emit('input', result);
      }
    }
  }
</script>
<style>
.text-item {
  box-shadow: 0 0 0 1px #ccc;
  border: none;
  position: absolute;
  line-height: 1.0;
  touch-action: none;
}
.text {
  outline: 0px solid transparent;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  border-style: none;
  padding: 0;
  width: 100%;
  background: transparent;
}
.text-item.focused .ul-corner, .text-item.focused .ll-corner, .text-item.focused .ur-corner, .text-item.focused .lr-corner {
  display: none;
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';
import cuid from 'cuid';

Vue.use(Vuex);

let nextX = 0;
let nextY = 0;
let nextDelta = 10;
const defaultTextWidth = 144;
const defaultTextHeight = 48;

const store = new Vuex.Store({
  state: {
    fold: null,
    front: true,
    screen: 'SplashScreen',
    page: 0,
    pages: [],
    canvasSize: {
      width: null,
      height: null
    },
    itemId: null,
    fonts: [
      "AdventPro-Thin",
      "Cookie-Regular",
      "CourierPrime-Regular",
      "CraftyGirls-Regular",
      "DecovarAlpha-VF",
      "Dekko-Regular",
      "DrSugiyama-Regular",
      "EastSeaDokdo-Regular",
      "EaterCaps-Regular",
      "FasterOne-Regular",
      "Felipa-Regular",
      "FreckleFace-Regular",
      "FrederickatheGreat-Regular",
      "Griffy-Regular",
      "IndieFlower-Regular",
      "Italianno-Regular",
      "JacquesFrancoisShadow-Regular",
      "Kodchasan-Regular",
      "KumarOne-Regular",
      "LiuJianMaoCao-Regular",
      "LobsterTwo-Regular",
      "LoveYaLikeASister",
      "LovedbytheKing",
      "MarckScript-Regular",
      "MedievalSharp",
      "Megrim",
      "Molle-Regular",
      "NanumBrushScript-Regular"     
    ],
    items: [],
    button: null,
    selections: {},
    nextX: 0,
    nextY: 0
  },
  mutations: {
    setScreen(state, screen) {
      state.screen = screen;
    },
    setFold(state, fold) {
      state.fold = fold;
      state.pages = [];
      for (let i = 0; (i < fold.pages.length); i++) {
        state.pages.push({
          index: i,
          label: i + 1,
          dimensions: fold.dimensions,
          items: []
        });
      }
    },
    setFront(state, front) {
      state.front = front;
    },
    setPage(state, page) {
      state.pages[state.page].items = state.items;
      state.page = page;
      state.items = state.pages[state.page].items;
      state.itemId = null;
    },
    commitPage(state) {
      state.pages[state.page].items = state.items;
    },
    addItem(state, item) {
      state.items.push(item);
    },
    updateItem(state, { item, content }) {
      const index = state.items.findIndex(_item => item.id === _item.id);
      if (index > -1) {
        const offLeft = (content.left + content.width < 8);
        const offTop = (content.top + content.height < 8);
        const offRight = (content.left + 8 > state.fold.width);
        const offBottom = (content.top + 8 > state.fold.height);
        if (offLeft || offTop || offRight || offBottom) {
          // Thrown away
          state.items.splice(index, 1);
        } else {
          state.itemId = item.id;
          // Updated items move to the top (last item)
          state.items = [ ...state.items.slice(0, index), ...state.items.slice(index + 1), {
            ...item,
            content
          } ];
        }
      }
    },
    setCanvasSize(state, size) {
      state.canvasSize = size;
    },
    setButton(state, button) {
      if (button.id === 'text') {
        this.commit('addItem', {
          type: 'TextItem',
          id: cuid(),
          content: {
            text: '',
            color: state.selections.color,
            fontFamily: state.selections.fontFamily,
            fontSize: state.selections.fontSize,
            textAlign: state.selections.textAlign,
            width: defaultTextWidth,
            height: defaultTextHeight,
            left: state.fold.width / 2 - defaultTextWidth / 2 + nextX,
            top: state.fold.height / 2 - defaultTextHeight / 2 + nextY
          }
        });
        nextX += nextDelta;
        nextY += nextDelta;
        if ((nextX + state.fold.width / 2 >= state.fold.width - defaultTextWidth / 2) || (nextY + state.fold.height / 2 >= state.fold.height - defaultTextHeight / 2) ||
          (nextX + state.fold.width / 2 - defaultTextWidth / 2 < 0) || (nextY + state.fold.height / 2 - defaultTextHeight / 2 < 0)) {
          nextX = 0;
          nextY = 0;
          nextDelta = -nextDelta;
        }
      }
      // The remaining buttons establish a new mode
      state.button = button;
    },
    setSelection(state, { button, value }) {
      button = button || state.button;
      Vue.set(state.selections, button.id, value);
      const attributes = [ 'color', 'fontFamily', 'fontSize', 'textAlign' ];
      if (state.itemId && attributes.includes(button.id)) {
        const item = state.items.find(item => item.id === state.itemId);
        if (item) {
          Vue.set(item, 'content', {
            ...item.content,
            [button.id]: value
          });
        }
      }
    }
  },
  getters: {
    // invoke as this.$store.getters.toPoints(pixels)
    toPoints: (state) => (n) => {
      const ratio = state.fold.height / state.canvasSize.height;
      return n * ratio;
    },
    // invoke as this.$store.getters.toPixels(points)
    toPixels: (state) => (n) => {
      const ratio = state.fold.height / state.canvasSize.height;
      return n / ratio;
    }
  }
});

export default store;


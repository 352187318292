<template>
  <div class="screen">
    <nav v-if="menu" class="main">
      <img v-if="!back" class="logo" alt="App logo" src="../assets/logo.svg">
      <button @click="$emit('back')" class="back" v-else>
        <ArrowLeftIcon />
      </button>
      <span class="title">{{ title }}</span>

      <slot name="action"></slot>
    </nav>
    <div v-if="content" class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ArrowLeftIcon from 'vue-material-design-icons/ArrowLeft.vue';

export default {
  components: {
    ArrowLeftIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    back: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    content: {
      type: Boolean,
      required: false,
      default() {
        return true;
      }
    },
    menu: {
      type: Boolean,
      required: false,
      default() {
        return true;
      }
    },
  }
}
</script>

<style scoped>
.screen {
  position: relative;
  box-sizing: border-box;
  font-family: Noto-Sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  background-color: var(--bg-color);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 0;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100); /* Necessary for mobile safari */
}

.main {
  margin-top: 16px;
  margin-left: 48px;
  margin-right: 12px;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.title {
  font-family: Cabin-Sketch;
  padding-left: 10px;
  font-weight: bold;
  font-size: 30px;
  flex-grow: 0;
}

.back {
  color: black;
  background-color: var(--bg-color);
  height: 48px;
  border: 0;
  padding: 0;
  margin: 0;
}

.back svg {
  height: 48px;
}

.content {
  margin-top: 35px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.logo {
  height: 56px;
}

.menu {
  margin-top: 16px;
  margin-left: 36px;
  margin-right: 12px;
  color: #427FED;
}

@media screen and (min-width: 411px) {
  .title {
    font-size: 36px;
  }
}

</style>
<template>
  <div class="image-upload">
    <label for="camera-input">
      <CameraIcon />
    </label>
    <input type="file" id="camera-input" ref="input" capture="camera" accept="image/*" @change="change" />
  </div>
</template>
<script>
import CameraIcon from 'vue-material-design-icons/Camera.vue';
import cuid from 'cuid';

export default {
  components: {
    CameraIcon
  },
  methods: {
    change(e) {
      const that = this;
      if (e.target.files && e.target.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            that.$store.commit('addItem', {
              type: 'ImageItem',
              id: cuid(),
              content: {
                width: 144,
                height: img.height * (144 / img.width),
                left: this.$store.state.fold.width / 2 - 72,
                top: this.$store.state.fold.height / 2 - img.height / img.width * 72,
                src: e.target.result
              }
            });
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  }
}
</script>
<style scoped>
  .image-upload > input {
    visibility:hidden;
    width:0px;
    height:0px;
  }
</style>
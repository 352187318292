<template>
  <VueDraggableResizable :lockAspectRatio="true" :w="w" :h="h" :x="x" :y="y" class="text-item" @dragstop="onDragStop" @resizestop="onResizeStop">
    <img draggable="false" ref="img" :src="src" />
    <!-- <div class="lr-corner" @pointerdown.stop="onCornerDown('lr', $event)" @pointermove.stop="onCornerMove" @pointerup.stop="onCornerUp"></div> -->
  </VueDraggableResizable>
</template>
<script>
  import VueDraggableResizable from 'vue-draggable-resizable';
  import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
  import store from '../store.js';

export default {
    components: {
      VueDraggableResizable
    },
    props: {
      content: Object
    },
    data() {
      const width = this.content.width;
      const height = this.content.height;
      const left = this.content.left || 0;
      const top = this.content.top || 0;
      return {
        w: store.getters.toPixels(width),
        h: store.getters.toPixels(height),
        x: store.getters.toPixels(left),
        y: store.getters.toPixels(top),
        left,
        top,
        width,
        height
      };
    },
    computed: {
      src() {
        return this.content.src;
      }
    },
    methods: {
      onDragStop(left, top) {
        this.left = store.getters.toPoints(left);
        this.top = store.getters.toPoints(top);
        this.emitDimensions();
      },
      onResizeStop(left, top, width, height) {
        this.left = store.getters.toPoints(left);
        this.top = store.getters.toPoints(top);
        this.width = store.getters.toPoints(width);
        this.height = store.getters.toPoints(height);
        this.emitDimensions();
      },
      emitDimensions() {
        this.$emit('input', {
          ...this.content,
          left: this.left,
          top: this.top,
          width: this.width,
          height: this.height
        });
      }
    }
  }
</script>
<style scoped>
.image-item {
  border: 2px dashed black;
  position: absolute;
  line-height: 1.0;
  touch-action: none;
}
img {
  pointer-events: none;
  user-select: none;
  width: 100%;
}
</style>

<template>
  <Screen :back="true" @back="back" title="personalize">
    <div class="top-box">
      <Atlas />
      <!-- Yes this is an old school form submission, best odds of all platforms
        treating the redirect to PDF as a real thing -->
      <form @submit="submit" class="print" method="POST" action="/pdfs" enctype="multipart/form-data"><input type="hidden" name="data" ref="data" /><button type="submit"><Printer /></button></form>
    </div>
    <Canvas />
    <Palette class="palette" />
  </Screen>
</template>

<script>
import Screen from './Screen.vue';
import Atlas from './Atlas.vue';
import Canvas from './Canvas.vue';
import Palette from './Palette.vue';
import Printer from 'vue-material-design-icons/Printer.vue';

// eslint-disable-next-line no-unused-vars

export default {
  components: {
    Screen,
    Atlas,
    Canvas,
    Palette,
    Printer
  },
  methods: {
    submit() {
      this.$store.commit('commitPage');
      this.$refs.data.value = JSON.stringify({
        pages: this.$store.state.pages,
        fold: this.$store.state.fold
      });
      // We have the data, now carry right on with the form submission
    },
    back() {
      this.$store.commit('setScreen', 'ChooseFoldScreen');
    },
  }
}

</script>
<style scoped>
.top-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 24px;
  margin-bottom: 12px;
}
.print {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.print button {
  border: 0;
  width: 48px;
  height: 48px;
  background-color: white;
  color: black;
  padding: 0;
  border-radius: 50%;
}
.palette {
  max-width: 480px;
  margin: auto;
}
@media screen and (max-width: 480px) {
  .palette {
    max-width: 100%;
  }
}
</style>
import Vue from 'vue';
import store from './store';
import App from './App.vue';
import { MdSwitch } from 'vue-material/dist/components'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import TextareaAutosize from 'vue-textarea-autosize'

Vue.use(MdSwitch);
Vue.use(TextareaAutosize);

// https://forum.vuejs.org/t/vue-style-visibility-hidden-on-v-show-how/58293
Vue.directive('visible', function(el, binding) {
	el.style.visibility = binding.value ? 'visible' : 'hidden';
});
Vue.config.productionTip = false;
(() => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  // We listen to the resize event
  window.addEventListener('resize', () => {
    // We execute the same script as before
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
})();

new Vue({
  store,
  render: h => h(App)
}).$mount('#app');

<template>
  <div class="palette">
    <div class="buttons-box">
      <div class="buttons">
        <button v-for="button in buttons" :key="button.id" :class="buttonClasses(button)" @click="buttonChoice(button)">
          <component class="button-icon" v-if="button.icon" :is="button.icon" />
          <span v-if="button.text">{{ button.text() }}</span>
        </button>
      </div>
    </div>
    <div :class="drawerBoxClasses">
      <div :class="drawerClasses" v-visible="drawerChoices">
        <button v-for="choice in drawerChoices" :class="drawerChoiceClasses(choice)" :style="choice.style || {}" :key="choice.value" @click="drawerChoice(choice)">
          <component v-if="choice.icon" :is="choice.icon" />
          <span v-else>{{ choice.label }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FormatAlignLeft from 'vue-material-design-icons/FormatAlignLeft.vue';
import FormatAlignCenter from 'vue-material-design-icons/FormatAlignCenter.vue';
import FormatAlignRight from 'vue-material-design-icons/FormatAlignRight.vue';
import Palette from 'vue-material-design-icons/Palette.vue';
// import Crop from 'vue-material-design-icons/Crop.vue';
import Image from './Image.vue';
import Camera from './Camera.vue';
import FormatText from 'vue-material-design-icons/FormatText.vue';
// import WhiteBalanceSunny from 'vue-material-design-icons/WhiteBalanceSunny.vue';
import ImageIcon from 'vue-material-design-icons/Image.vue';

const modalButtons = [ 'fontSize', 'fontFamily', 'textAlign', 'color', 'crop' ];

export default {
  data() {
    const that = this;
    // (window.cordova.platformId === 'android')
    const colors = [
      '#FF0606',
      '#0904FE',
      '#0BA708',
      '#22F2F2',
      '#FFFFFF',
      '#000000',
      '#FFC805',
      '#D00AF0',
      '#08AEE2',
      '#38F8BE',
      '#870303',
      '#F863A1',
      '#F48160',
      '#4285F4',
      '#4A5B64',
      '#B7B7B7'
    ];
    const data = {
      buttons: [
        {
          id: 'fontSize',
          text() {
            return that.selections.fontSize;
          },
          drawer: true,
          def: '24pt',
          choices: [
            {
              label: '10pt',
              value: '10pt'
            },
            {
              label: '16pt',
              value: '16pt'
            },
            {
              label: '24pt',
              value: '24pt'
            },
            {
              label: '32pt',
              value: '32pt'
            },
            {
              label: '48pt',
              value: '48pt'
            },
            {
              label: '60pt',
              value: '60pt'
            },
            {
              label: '90pt',
              value: '90pt'
            },
          ]
        },
        {
          id: 'fontFamily',
          text() {
            return 'ABC';
          },
          drawer: true,
          scroll: true,
          choices: this.$store.state.fonts.map(font => ({
            value: font,
            label: 'ABC',
            style: {
              fontFamily: font,
              paddingRight: '8px',
              fontSize: '16px'
            }
          }))
        },
        {
          id: 'textAlign',
          icon: FormatAlignLeft,
          drawer: true,
          choices: [
            {
              icon: FormatAlignLeft,
              value: 'left'
            },
            {
              icon: FormatAlignCenter,
              value: 'center'
            },
            {
              icon: FormatAlignRight,
              value: 'right'
            }
          ]
        },
        {
          id: 'color',
          icon: Palette,
          drawer: true,
          scroll: true,
          choices: colors.map(color => ({
            value: color,
            class: color === '#FFFFFF' ? 'color-white' : 'color',
            style: {
              width: '40px',
              height: '40px',
              backgroundColor: color
            }
          }))
        },
        // {
        //   id: 'crop',
        //   icon: Crop
        // },
        {
          id: 'image',
          icon: ImageIcon,
          drawer: true,
          stateless: true,
          choices: [
            {
              // Actually a full blown component with a hidden
              // file input field
              icon: Image,
              value: 'image'
            },
            {
              // Actually a full blown component with a hidden
              // file input field
              icon: Camera,
              value: 'camera'
            },
          ]
        },
        {
          id: 'text',
          icon: FormatText
        }
        // {
        //   id: 'brightness',
        //   icon: WhiteBalanceSunny
        // }
      ]
    };
    for (const button of data.buttons) {
      if (button.drawer && button.choices) {
        this.$store.commit('setSelection', {
          button,
          value: button.def || button.choices[0].value
        });
      }
    }
    return data;
  },
  computed: {
    button() {
      return this.$store.state.button;
    },
    selections() {
      return this.$store.state.selections;
    },
    drawerChoices() {
      return this.button && this.button.drawer && this.button.choices;
    },
    drawerClasses() {
      return {
        drawer: true,
        scroll: this.button && this.button.scroll
      }
    },
    drawerBoxClasses() {
      return {
        'drawer-box': true,
        active: modalButtons.includes(this.buttonId)
      };
    },
    buttonId() {
      return this.$store.state.button?.id;
    }
  },
  methods: {
    buttonChoice(button) {
      this.$store.commit('setButton', button);
    },
    drawerChoice(choice) {
      this.$store.commit('setSelection', {
        button: this.button,
        value: choice.value
      });
    },
    buttonClasses(button) {
      if (button.id === this.buttonId) {
        if (modalButtons.includes(this.buttonId)) {
          return {
            active: true
          };
        }
      }
      return {};
    },
    drawerChoiceClasses(choice) {
      const classes = {
        choice: true
      };
      if (choice.class) {
        classes[choice.class] = true;
      }
      if (!this.button.stateless) {
        if (this.button && (choice.value === this.selections[this.button.id])) {
          classes.active = true;
        }
      }
      return classes;
    }
  }
}
</script>

<style scoped>
  .buttons-box {
    width: 100%;
    padding: 4px 19px;
  }
  .buttons {
    height: 42px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .button-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .drawer-box {
    width: 100%;
    padding: 0;
  }
  .drawer-box.active {
    background-color: white;
  }
  .drawer {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
  .drawer.scroll {
    display: block;
    overflow: scroll;
    white-space: nowrap;
    max-width: 100%;
  }
  ::-webkit-scrollbar {
      display: none;
  }
  button {
    display: inline-flex;
    height: 38px;
    padding-top: 2px;
    flex-grow: 1;
    margin: 0;
    border: 0;
    color: black;
    background-color: var(--bg-color);
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .drawer button {
    background-color: white;    
  }
  button.active {
    filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.5));
  }
  .choice.active {
    filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.5));
  }
  .choice.color.active {
    box-shadow: inset 0px 0px 4px 2px white;
  }
  .choice.color-white.active {
    box-shadow: inset 0px 0px 4px 2px black;
  }
</style>

<template>
  <div id="app">
    <component
      :is="$store.state.screen"
    />
    <div v-for="font in $store.state.fonts" :key="font" class="lazy-font-loader" :style="{ fontFamily: font }">
      i contain text so the font will load
    </div>
  </div>
</template>

<script>

import SplashScreen from './components/SplashScreen.vue';
import ChooseFoldScreen from './components/ChooseFoldScreen.vue';
import PersonalizeScreen from './components/PersonalizeScreen.vue';
export default {
  name: 'App',
  components: {
    SplashScreen, ChooseFoldScreen, PersonalizeScreen
  },
  data() {
    return {
      screen: 'SplashScreen',
      fold: null,
      front: true
    }
  },
  methods: {
    printTest() {
      window.cordova.plugins.printer.print('<h1 style="background-color: #eee; width: 100%; height: 2em">Hello World!</h1>', {
        name: 'My Card',
        margin: false
      });
    }
  }
}
</script>

<style>
:root {
  --bg-color: #F26F35;
}

@media screen and (min-width: 1024px) {
  :root {
    --bg-color: rgba(252,223,210,1);
  }
}


body {
  margin: 0;
  background-color: var(--bg-color);
  font-family: Noto-Sans;
  overflow: hidden;
}
.lazy-font-loader {
  opacity: 0;
  height: 0;
}

@font-face {
  font-family: Cabin-Sketch;
  src: url("~@/assets/fonts/CabinSketch-Bold.ttf");
}

@font-face {
  font-family: Noto-Sans;
  src: url("~@/assets/fonts/NotoSans-Regular.ttf");
}

@font-face {
  font-family: AdventPro-Thin;
  src: url("~@/assets/fonts/AdventPro-Thin.ttf");
}

@font-face {
  font-family: Cookie-Regular;
  src: url("~@/assets/fonts/Cookie-Regular.ttf");
}

@font-face {
  font-family: CourierPrime-Regular;
  src: url("~@/assets/fonts/CourierPrime-Regular.ttf");
}

@font-face {
  font-family: CraftyGirls-Regular;
  src: url("~@/assets/fonts/CraftyGirls-Regular.ttf");
}

@font-face {
  font-family: DecovarAlpha-VF;
  src: url("~@/assets/fonts/DecovarAlpha-VF.ttf");
}

@font-face {
  font-family: Dekko-Regular;
  src: url("~@/assets/fonts/Dekko-Regular.ttf");
}

@font-face {
  font-family: DrSugiyama-Regular;
  src: url("~@/assets/fonts/DrSugiyama-Regular.ttf");
}

@font-face {
  font-family: EastSeaDokdo-Regular;
  src: url("~@/assets/fonts/EastSeaDokdo-Regular.ttf");
}

@font-face {
  font-family: EaterCaps-Regular;
  src: url("~@/assets/fonts/EaterCaps-Regular.ttf");
}

@font-face {
  font-family: FasterOne-Regular;
  src: url("~@/assets/fonts/FasterOne-Regular.ttf");
}

@font-face {
  font-family: Felipa-Regular;
  src: url("~@/assets/fonts/Felipa-Regular.ttf");
}

@font-face {
  font-family: FreckleFace-Regular;
  src: url("~@/assets/fonts/FreckleFace-Regular.ttf");
}

@font-face {
  font-family: FrederickatheGreat-Regular;
  src: url("~@/assets/fonts/FrederickatheGreat-Regular.ttf");
}

@font-face {
  font-family: Griffy-Regular;
  src: url("~@/assets/fonts/Griffy-Regular.ttf");
}

@font-face {
  font-family: IndieFlower-Regular;
  src: url("~@/assets/fonts/IndieFlower-Regular.ttf");
}

@font-face {
  font-family: Italianno-Regular;
  src: url("~@/assets/fonts/Italianno-Regular.ttf");
}

@font-face {
  font-family: JacquesFrancoisShadow-Regular;
  src: url("~@/assets/fonts/JacquesFrancoisShadow-Regular.ttf");
}

@font-face {
  font-family: Kodchasan-Regular;
  src: url("~@/assets/fonts/Kodchasan-Regular.ttf");
}

@font-face {
  font-family: KumarOne-Regular;
  src: url("~@/assets/fonts/KumarOne-Regular.ttf");
}

@font-face {
  font-family: LiuJianMaoCao-Regular;
  src: url("~@/assets/fonts/LiuJianMaoCao-Regular.ttf");
}

@font-face {
  font-family: LobsterTwo-Regular;
  src: url("~@/assets/fonts/LobsterTwo-Regular.ttf");
}

@font-face {
  font-family: LoveYaLikeASister;
  src: url("~@/assets/fonts/LoveYaLikeASister.ttf");
}

@font-face {
  font-family: LovedbytheKing;
  src: url("~@/assets/fonts/LovedbytheKing.ttf");
}

@font-face {
  font-family: MarckScript-Regular;
  src: url("~@/assets/fonts/MarckScript-Regular.ttf");
}

@font-face {
  font-family: MedievalSharp;
  src: url("~@/assets/fonts/MedievalSharp.ttf");
}

@font-face {
  font-family: Megrim;
  src: url("~@/assets/fonts/Megrim.ttf");
}

@font-face {
  font-family: Molle-Regular;
  src: url("~@/assets/fonts/Molle-Regular.ttf");
}

@font-face {
  font-family: NanumBrushScript-Regular;
  src: url("~@/assets/fonts/NanumBrushScript-Regular.ttf");
}
</style>

<template>
  <div class="canvas-box" :style="boxStyles">
    <div class="canvas">
      <component v-for="item in $store.state.items" :key="item.id" :is="item.type" :content="item.content" @input="onInput(item, $event)" />
    </div>
  </div>
</template>

<script>
import TextItem from './TextItem.vue';
import ImageItem from './ImageItem.vue';

export default {
  components: {
    TextItem,
    ImageItem
  },
  data() {
    return {
      mounted: false
    };
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.updateCanvasSize).observe(this.$el);
    this.updateCanvasSize();
    this.mounted = true;
  },
  destroyed() {
    this.resizeObserver.disconnect();
  },
  computed: {
    boxStyles() {
      if (!this.mounted) {
        return {};
      }
      const fold = this.$store.state.fold;
      const pageRatio = fold.width / fold.height;
      const dimensions = this.$store.state.canvasSize;
      const componentRatio = dimensions.width / dimensions.height;
      const result = {};
      if (pageRatio < componentRatio) {
        const occupiedPortion = pageRatio / componentRatio;
        const unoccupiedPortion = 1.0 - occupiedPortion;
        const paddingPercentage = unoccupiedPortion / 2 * 100;
        result.padding = `0 ${paddingPercentage}% 0 ${paddingPercentage}%`;
      } else {
        const occupiedPortion = componentRatio / pageRatio;
        const unoccupiedPortion = 1.0 - occupiedPortion;
        const paddingPercentage = unoccupiedPortion / 2 * 100;
        result.padding = `${paddingPercentage}% 0 ${paddingPercentage}% 0`;
      }
      return result;
    }
  },
  methods: {
    onInput(item, content) {
      this.$store.commit('updateItem', { item, content });
    },
    updateCanvasSize() {
      const rect = this.$el.getBoundingClientRect();
      const size = {
        width: rect.right - rect.left,
        height: rect.bottom - rect.top
      };
      this.$store.commit('setCanvasSize', size);
    }
  }
}
</script>

<style scoped>
  .canvas-box {
    flex-grow: 1.0;
    background-color: inherit;
    position: relative;
    overflow: hidden;
    display: flex;
  }
  .canvas {
    width: 100%;
    flex-grow: 1.0;
    background-color: white;
    position: relative;
    overflow: hidden;
  }
</style>

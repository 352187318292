<template>
  <Screen :menu="false" title="DIY cardmaker">
    <div class="spacer"></div>
    <h1><img class="logo" alt="App logo" src="../assets/logo.svg"> cardmaker</h1>
    <div class="spacer"></div>
  </Screen>
</template>

<script>
import Screen from './Screen.vue';
export default {
  components: {
    Screen
  },
  mounted() {
    setTimeout(() => {
      this.$store.commit('setScreen', 'ChooseFoldScreen');
    }, 2000);
  }
}
</script>
<style scoped>
.logo {
  vertical-align: middle;
  height: 15vw;
}

h1 {
  font-family: Cabin-Sketch;
  text-align: center;
  font-size: 15vw;
}

.spacer {
  flex-grow: 1;
}

.splash {

}
/* .bottom-spacer {
  flex-grow: 1.25;
} */
</style>
<template>
  <Screen title="choose your fold">
    <ul class="folds">
      <li :class="[ 'fold', fold.name ]" v-for="fold in folds" :key="fold.name" @click="selectFold(fold)">
        <img :src="fold.svg.src" :style="foldStyle(fold)" />
      </li>
    </ul>
    <div class="bottom-decoration">
      <div class="gradient"></div>
    </div>
  </Screen>
</template>

<script>
import Screen from './Screen.vue';
import Trifold from '../assets/folds/trifold.svg';
import BifoldVertical from '../assets/folds/bifold-vertical.svg';
// import LinearQuad from '../assets/folds/linear-quad.svg';
import QuarterFold from '../assets/folds/quarter-fold.svg';
import BifoldEasel from '../assets/folds/bifold-easel.svg';
// import Triptych from '../assets/folds/triptych.svg';

export default {
  components: {
    Screen
  },
  data() {
    return {
      folds: [
        {
          name: 'trifold',
          label: 'Trifold',
          svg: {
            src: Trifold,
            scale: 1.0
          },
          back: false,
          width: 2.66666666666666 * 72,
          height: 7.5 * 72,
          pages: [
            {
              // For PDF generation
              x: 0.5 * 72,
              y: 0.5 * 72,
              rotate: 90,
              side: 0
            },
            {
              x: 0.5 * 72,
              y: (1.5 + 2.66666666666666) * 72,
              rotate: 90,
              side: 0
            },
            {
              x: 0.5 * 72,
              y: (2.5 + 2.66666666666666 * 2) * 72,
              rotate: 90,
              side: 0
            }
          ]
        },
        {
          name: 'bifold-vertical',
          label: 'Bifold Vertical',
          svg: {
            src: BifoldVertical,
            translate: -1,
            scale: 0.95
          },
          back: false,
          width: 4.5 * 72,
          height: 7.5 * 72,
          pages: [
            {
              x: 0.5 * 72,
              y: 6.0 * 72,
              rotate: 90,
              side: 0
            },
            {
              x: 0.5 * 72,
              y: 0.5 * 72,
              rotate: 90,
              side: 1
            },
            {
              x: 0.5 * 72,
              y: 6.0 * 72,
              rotate: 90,
              side: 1
            },
            {
              x: 0.5 * 72,
              y: 0.5 * 72,
              rotate: 90,
              side: 0
            }
          ],
        },
        // {
        //   name: 'linear-quad',
        //   label: 'Linear Quad',
        //   svg: {
        //     src: LinearQuad,
        //     scale: 0.9
        //   },
        //   pages: 4,
        //   back: 'back'
        // },
        {
          name: 'quarter-fold',
          label: 'Quarter Fold',
          svg: {
            src: QuarterFold,
            scale: 0.9,
            translate: 3
          },
          back: false,
          width: 3.25 * 72,
          height: 4.5 * 72,
          pages: [
            {
              x: 4.75 * 72,
              y: 6 * 72,
              rotate: 0,
              side: 0
            },
            {
              x: 4.75 * 72,
              y: 0.5 * 72,
              rotate: 180,
              side: 0
            },
            {
              x: 0.5 * 72,
              y: 0.5 * 72,
              rotate: 180,
              side: 0
            },
            {
              x: 0.5 * 72,
              y: 6.0 * 72,
              rotate: 0,
              side: 0
            },
          ]
        },
        {
          name: 'bifold-easel',
          label: 'Bifold Easel',
          svg: {
            src: BifoldEasel,
            scale: 0.95
          },
          back: false,
          width: 7.5 * 72,
          height: 4.5 * 72,
          pages: [
            {
              x: 0.5 * 72,
              y: 0.5 * 72,
              rotate: 180,
              side: 0
            },
            {
              x: 0.5 * 72,
              y: 6.0 * 72,
              rotate: 0,
              side: 0
            }
          ]
        },
        // {
        //   name: 'triptych',
        //   label: 'Triptych',
        //   svg: {
        //     src: Triptych,
        //     scale: 1.0
        //   },
        //   pages: 3,
        //   back: false
        // }
      ],
      fold: null
    }
  },
  methods: {
    foldStyle(fold) {
      const transform = `scale(${fold.svg.scale || 1.0}) translate(0, ${fold.svg.translate || 0.0}%)`;
      return {
        transform,
        transformOrigin: 'top center'
      };
    },
    selectFold(fold) {
      this.$store.commit('setFold', fold);
      this.$store.commit('setScreen', 'PersonalizeScreen');
    }
  }
}
</script>
<style scoped>
  .folds {
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 0;
    padding-inline-start: 0;
    list-style-type: none;
    display: grid;
    grid-gap: 29px 29px;
    grid-template-columns: calc(50% - 14.5px) calc(50% - 14.5px);
    grid-template-rows: 50% 50%;
    width: 70%;
    margin: auto;
    margin-bottom: 32px;
  }
  .fold {
    background-color: white;
    padding: 0;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }
  .label {
    margin-top: 6px;
    font-weight: bold;
    text-align: center;
  }
  .fold img {
    width: 100%;
  }
  ::-webkit-scrollbar {
      display: none;
  }
  .bottom-decoration {
    flex-grow: 1;
    position: relative;
    background-image: url("~@/assets/images/bottom-decoration.jpg");
    background-size: cover;
  }
  .gradient {
    position: absolute;
    background: linear-gradient(180deg, var(--bg-color) 27.39%, rgba(242, 111, 53, 0) 88.35%);
    width: 100%;
    height: 35px;
  }
  @media screen and (max-aspect-ratio: 1/1) {
    .folds {
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;
      width: 70%;
    }
  }
  @media screen and (min-aspect-ratio: 1/1) {
    .folds {
      grid-template-columns: auto auto auto auto;
      grid-template-rows: auto;
    }
  }
</style>
<template>
  <nav class="atlas">
    <MdSwitch v-if="$store.state.fold.back" class="front md-primary" v-model="$store.state.front">
      {{ frontLabel }}
    </MdSwitch>
    <div class="pages">
      <div
        v-for="page in $store.state.pages" :key="page.index"
        :class="pageClass(page)"
        :value="page.index"
        @click="setPage(page.index)"
      >
        {{ page.label }}
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    frontLabel() {
      return this.$store.state.front ? 'front' : this.$store.state.fold.back;
    }
  },
  methods: {
    pageClass(page) {
      return {
        active: this.$store.state.page === page.index,
        page: true
      };
    },
    setPage(index) {
      this.$store.commit('setPage', index);   
    }
  }
}
</script>

<style scoped>
  .atlas {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin: 0;
  }
  .front ::v-deep .md-switch-container {
    order: 2;
  }
  .front ::v-deep .md-switch-label {
    order: 1;
    padding-right: 8px;
  }
  .front ::v-deep label {
    color: #427FED;
  }
  .pages {
    display: flex;
    flex-direction: row;
    font-family: Cabin-Sketch;
  }
  .page {
    color: black;
    background-color: #fdfdfd;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    padding: 20px 16px;
    cursor: pointer;
  }
  .page:last-child {
    border-right: 1px solid black;
    margin-right: 0;
  }
  .page.active {
    font-weight: bold;
    background-color: #fff;
    border: 1px solid #fff;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0.6);
  }
</style>
